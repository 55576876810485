@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #fff;
    color: #1a1a1a;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

html.light {
    background: #fff;
    color: #1a1a1a;
}

.loader-block-scroll {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-size: cover;
    pointer-events: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

body.modal-open {
    min-height: 100vh;
    overflow-y: hidden;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    font-weight: 300;
    color: #697f85;
    font-size: 14px;
    line-height: 20px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    font-weight: 300;
    color: #697f85;
    font-size: 14px;
    line-height: 20px;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    font-weight: 300;
    color: #697f85;
    font-size: 14px;
    line-height: 20px;
}

.carousel .thumbs-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.carousel .thumbs-wrapper .thumbs .thumb img {
    max-height: 56px;
}

.default-carousel .carousel .slider-wrapper .slider .slide {
    text-align: left;
}

.default-carousel .carousel .slide iframe {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    height: 100% !important;
}

.default-icon path {
    stroke: #4f5a61;
}

.green-icon path {
    stroke: #64b054;
}

.grey-icon path {
    stroke: #697f85;
}

.yellow-icon path {
    stroke: #ffc34a;
}

.red-icon path {
    stroke: #e00000;
}

.red-icon-second path {
    stroke: #FF557C;
}

.orange-icon path {
    stroke: #f47c48;
}

.orange-icon-second path {
    stroke: #F47C48;
}

.white-icon path {
    stroke: #fff;
}

.yellow-icon-fill path {
    fill: #ffc34a;
    stroke: #ffc34a;
}

input[type='radio'] {
    accent-color: #1a1a1a;
}

.carousel-cover-images .control-arrow {
    max-height: 450px !important;
}

input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.default-carousel .thumbs-wrapper .thumbs .thumb {
    padding: 0px;
    border-radius: 9px;
}
.default-carousel .carousel {
    position: relative;
}

.blur-10 {
    filter: blur(10px);
}

.blur-3 {
    filter: blur(3px);
}

.blur-5 {
    filter: blur(5px);
}

body::-webkit-scrollbar {
    width: 12px;
}

body::-webkit-scrollbar-thumb {
    background-color: transparent;
}

body::-webkit-scrollbar-track {
    background-color: transparent;
}

body {
    -ms-overflow-style: none;
}

body::-ms-scrollbar {
    display: none;
}

.light-icon path {
    stroke-width: 1;
}