$types: (
    success: (
        color: (
            default: #ffffff,
            hover: #ffffff,
            click: #ffffff,
            focus: #ffffff,
        ),
        background: (
            default: #64b054,
        ),
        border: (
            default: none,
        ),
    ),
    success-outline: (
        color: (
            default: #64b054,
            hover: #64b054,
            click: #64b054,
            focus: #64b054,
        ),
        background: (
            default: #ffffff,
        ),
        border: (
            default: 1px solid #64b054,
        ),
    ),
    secondary: (
        color: (
            default: #64b054,
            hover: #64b054,
            click: #64b054,
            focus: #64b054,
        ),
        background: (
            default: #e8f5e6,
        ),
        border: (
            default: none,
        ),
    ),
    secondary-outline: (
        color: (
            default: #64b054,
            hover: #64b054,
            click: #64b054,
            focus: #64b054,
        ),
        background: (
            default: #e8f5e6,
        ),
        border: (
            default: 1px solid #64b054,
        ),
    ),
    transparent: (
        color: (
            default: #23262a,
            hover: #23262a,
            click: #23262a,
            focus: #23262a,
        ),
        background: (
            default: transparent,
        ),
        border: (
            default: 1px solid #cacaca,
        ),
    ),
    danger: (
        color: (
            default: #ffffff,
            hover: #ffffff,
            click: #ffffff,
            focus: #ffffff,
        ),
        background: (
            default: #e00000,
        ),
        border: (
            default: none,
        ),
    ),
    danger-outline: (
        color: (
            default: #e00000,
            hover: #e00000,
            click: #e00000,
            focus: #e00000,
        ),
        background: (
            default: #ffffff,
        ),
        border: (
            default: 1px solid #e00000,
        ),
    ),
);

.btn {
    display: flex;
    border: none;
    transition: outline 200ms;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 13px 24px;
    border-radius: 6px;
}

@each $type in map-keys($types) {
    .#{$type} {
        color: map-get(map-get(map-get($types, $type), color), 'default');
        background-color: map-get(map-get(map-get($types, $type), background), 'default') !important;
        border: map-get(map-get(map-get($types, $type), border), 'default') !important;

        &:hover {
            color: map-get(map-get(map-get($types, $type), color), 'hover');
            background-color: map-get(map-get(map-get($types, $type), background), 'hover') !important;
        }

        &:focus-visible {
            color: map-get(map-get(map-get($types, $type), color), 'focus');
            background-color: map-get(map-get(map-get($types, $type), background), 'focus') !important;
        }

        &:active {
            color: map-get(map-get(map-get($types, $type), color), 'click');
            background-color: map-get(map-get(map-get($types, $type), background), 'click') !important;
        }

        &:disabled {
            cursor: default;
            color: map-get(map-get(map-get($types, $type), color), 'disabled');
            background-color: map-get(map-get(map-get($types, $type), background), 'disabled') !important;
        }
    }
}
