.loader {
    width: var(--spinner-width);
    height: var(--spinner-width);
    display: inline-block;
    position: relative;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: var(--spinner-width);
    height: var(--spinner-width);
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}
.loader::after {
    animation-delay: 1s;
}

.loader-black::before {
    background: #000 !important;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
