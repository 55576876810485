.icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
    }
}
