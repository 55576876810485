.link-wrapper {
    a {
        width: 100%;
    }
}

.chat-message {
    .icon {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    &:hover {
        .icon {
            display: inline-block !important;
        }
    }
}
